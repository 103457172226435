import React from 'react';
import LogoFooter from '../images/logo_footer.svg';
import FooterPrev from './footerPrev';
import FooterMenu from './menus/footerMenu';
import FooterLogo from '../images/logo_footer.png';
import FooterMenu1Desktop from './menus/footerMenu1Desktop';
import FooterMenu2Desktop from './menus/footerMenu2Desktop';
import FooterMenu3Desktop from './menus/footerMenu3Desktop';

const footer = () => {
    return (
        <div className="footer">
            <div className="footer_prev">
                <FooterPrev />
            </div>
            <div className="footer_copyright_desktop">
                <div>
                    <FooterMenu1Desktop />
                </div>
                <div>
                    <FooterMenu2Desktop />
                </div>
                <div>
                    <FooterMenu3Desktop />
                </div>
            </div>
            <div className="row footer_copyright">
                <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                    <img src={LogoFooter} />
                </div>
                <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                    <div className="footerMenuLine">
                        <FooterMenu />
                    </div>
                    <div className="footer_copiright_date">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; © {new Date().getFullYear()}
                        &nbsp;
                        <a href="https://www.thomsonreuters.com/en.html">
                            Thomson Reuters / Tax & Accounting
                        </a>
                    </div>
                </div>
            </div>
            <div className="row footer_copyright_mobile">
                <div id="accordion" className="accordion">
                    <div className="card mb-0">
                        <div
                            className="card-header collapsed"
                            data-toggle="collapse"
                            href="#collapseLast"
                        >
                            <a className="card-title">
                                <img src={FooterLogo} />
                            </a>
                        </div>

                        <div
                            id="collapseLast"
                            className="card-body collapse"
                            data-parent="#accordion"
                        >
                            <div className="footer_copy_menu">
                                <FooterMenu />
                            </div>
                            <div className="row footer_copiright_date">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; © {new Date().getFullYear()}
                                &nbsp;
                                <a href="https://www.thomsonreuters.com/en.html">
                                    Thomson Reuters / Tax & Accounting
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default footer;
