import React from 'react';

function FooterMenuView(data) {
    console.log('footerMenu', data);
    const jsonFooterMenuDataArray = [];
    data.globalJson.footer.footerMenu.map((item) =>
        jsonFooterMenuDataArray.push(
            <li key={item.id}>
                <a href={item.url}>{item.title}</a>
            </li>
        )
    );
    return jsonFooterMenuDataArray;
}
export default FooterMenuView;
