import React from 'react';
import FooterMenu1 from './menus/footerMenu1';

import FooterMenu2 from './menus/footerMenu2';
import FooterMenu3 from './menus/footerMenu3';

const FooterPrev = () => {
    return (
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <FooterMenu1 />
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <FooterMenu2 />
            </div>
            <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                <FooterMenu3 />
            </div>
        </div>
    );
};

export default FooterPrev;
