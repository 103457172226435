import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import FooterMenu2View from './footerMenu2View';

const FooterMenu2 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    footerPrev {
                        footerMenu2Title
                        footerMenu2 {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `}
        render={(data) => <section>{FooterMenu2View(data)}</section>}
    />
);
export default FooterMenu2;
