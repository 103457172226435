import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import FooterMenu2ViewDesktop from './footerMenu2ViewDesktop';

const FooterMenu2Desktop = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    footerPrev {
                        footerMenu2Title
                        footerMenu2 {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `}
        render={(data) => <div>{FooterMenu2ViewDesktop(data)}</div>}
    />
);
export default FooterMenu2Desktop;
