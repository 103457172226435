import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import Logo from '../images/logo.svg';
import { ScrollToLink } from '../utils/scrollToLink';

function getHeaderDataJson(data) {
    const { logoUrl, titleGetTouch } = data.globalJson.header;
    return (
        <>
            <div className="row no-gutters header">
                <div className="col-7 h_logo">
                    {/* <Link to="/">
                        <Img fixed={logoUrl.childImageSharp.fixed} />
                    </Link> */}
                    <Link to="/">
                        <img src={Logo} />
                    </Link>
                </div>
                <div className="col-5 h_gettouch">
                    <p>
                        {ScrollToLink('/contact', titleGetTouch)} <MdArrowForward />
                    </p>
                </div>
            </div>
        </>
    );
}

const Header = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    header {
                        titleGetTouch
                        logoUrl {
                            childImageSharp {
                                fixed(width: 117) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                        titleGetTouchUrl
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <div>{getHeaderDataJson(data)}</div>
            </>
        )}
    />
);
export default Header;
