import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import FooterMenu3ViewDesktop from './footerMenu3ViewDesktop';

const FooterMenu3Desktop = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    footerPrev {
                        footerMenu3Title
                        footerMenu3 {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `}
        render={(data) => <div>{FooterMenu3ViewDesktop(data)}</div>}
    />
);
export default FooterMenu3Desktop;
