import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import FooterMenu1ViewDesktop from './footerMenu1ViewDesktop';

const FooterMenu1Desktop = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    footerPrev {
                        footerMenu1Title
                        footerMenu1 {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `}
        render={(data) => <div>{FooterMenu1ViewDesktop(data)}</div>}
    />
);
export default FooterMenu1Desktop;
