import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import Footer from './footer';
import Header from './header';

if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"]');
}

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <div>
                <Helmet>
                </Helmet>
                <Header />
                <CookieConsent
                    location="bottom"
                    buttonText="Ok to continue"
                    declineButtonText="Decline"
                    cookieName="tr-cookie-consent"
                    disableStyles
                    containerClasses="row no-gutters text-center text-md-left cookie-container"
                    contentClasses="col-12 col-md-8 cookie-content-wrapper"
                    buttonClasses="col-12 mx-auto col-md-3 ml-md-auto mr-md-0 btn-cookie"
                >
                    <p className="tr-cookie-head">Our Privacy Statement & Cookie Policy</p>
                    <p className="tr-cookie-content">
                        Our Thomson Reuters Privacy Statement has changed to make our Privacy
                        Statement clearer and more concise. In addition, it has been updated to
                        address new laws in California. Please review our updated Privacy Statement
                        at the link below.
                    </p>
                    <ul className="tr-cookie-links">
                        <li className="tr-cookie-item">
                            <a href="https://www.thomsonreuters.com/en/privacy-statement.html">
                                Privacy Statement
                            </a>
                        </li>
                        <li className="tr-cookie-item">
                            <a href="https://www.thomsonreuters.com/en/privacy-statement.html#cookies">
                                Cookie Policy
                            </a>
                        </li>
                    </ul>
                </CookieConsent>
                <main>{children}</main>
                <Footer />
            </div>
        </>
    );
};
export default Layout;
