import React from 'react';

function FooterMenu2ViewDesktop(data) {
    console.log('footerMenu2', data);
    const jsonFooterMenu2DataArray = [];
    data.globalJson.footerPrev.footerMenu2.map((item) =>
        jsonFooterMenu2DataArray.push(
            <div className="footer-link">
                <a href={item.url}>{item.title}</a>
            </div>
        )
    );
    return (
        <>
            <div className="footer-body">
                <div className="footer-title">{data.globalJson.footerPrev.footerMenu2Title}</div>
                {jsonFooterMenu2DataArray}
            </div>
        </>
    );
}
export default FooterMenu2ViewDesktop;
