import React from 'react';

function FooterMenu1ViewDesktop(data) {
    console.log('footerMenu1', data);
    const jsonFooterMenu1DataArray = [];
    data.globalJson.footerPrev.footerMenu1.map((item) =>
        jsonFooterMenu1DataArray.push(
            <div className="footer-link">
                <a href={item.url}>{item.title}</a>
            </div>
        )
    );
    return (
        <>
            <div className="footer-body">
                <div class="footer-title">{data.globalJson.footerPrev.footerMenu1Title}</div>
                {jsonFooterMenu1DataArray}
            </div>
        </>
    );
}
export default FooterMenu1ViewDesktop;
