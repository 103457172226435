import React from 'react';

function FooterMenu2View(data) {
    console.log('footerMenu2', data);
    const jsonFooterMenu2DataArray = [];
    data.globalJson.footerPrev.footerMenu2.map((item) =>
        jsonFooterMenu2DataArray.push(
            <div
                key={item.id}
                id="collapseTwo"
                className="card-body collapse"
                data-parent="#accordion"
            >
                <a href={item.url}>{item.title}</a>
            </div>
        )
    );
    return (
        <>
            <div id="accordion" className="accordion">
                <div className="card mb-0">
                    <div
                        className="card-header collapsed"
                        data-toggle="collapse"
                        href="#collapseTwo"
                    >
                        <a className="card-title">{data.globalJson.footerPrev.footerMenu2Title}</a>
                    </div>

                    {jsonFooterMenu2DataArray}
                </div>
            </div>
        </>
    );
}
export default FooterMenu2View;
