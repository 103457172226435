import React from 'react';

function FooterMenu3ViewDesktop(data) {
    console.log('footerMenu3', data);
    const jsonFooterMenu3DataArray = [];
    data.globalJson.footerPrev.footerMenu3.map((item) =>
        jsonFooterMenu3DataArray.push(
            <div className="footer-link">
                <a href={item.url}>{item.title}</a>
            </div>
        )
    );
    return (
        <>
            <div className="footer-body">
                <div className="footer-title">{data.globalJson.footerPrev.footerMenu3Title}</div>
                {jsonFooterMenu3DataArray}
            </div>
        </>
    );
}
export default FooterMenu3ViewDesktop;
