import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import FooterMenuView from './footerMenuView';

const FooterMenu = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    footer {
                        footerMenu {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <section>
                <ul>{FooterMenuView(data)}</ul>
            </section>
        )}
    />
);
export default FooterMenu;
