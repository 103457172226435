import React from 'react';

function FooterMenu3View(data) {
    console.log('footerMenu3', data);
    const jsonFooterMenu3DataArray = [];
    data.globalJson.footerPrev.footerMenu3.map((item) =>
        jsonFooterMenu3DataArray.push(
            <div
                key={item.id}
                id="collapseThree"
                className="card-body collapse"
                data-parent="#accordion"
            >
                <a href={item.url}>{item.title}</a>
            </div>
        )
    );
    return (
        <>
            <div id="accordion" className="accordion">
                <div className="card mb-0">
                    <div
                        className="card-header collapsed"
                        data-toggle="collapse"
                        href="#collapseThree"
                    >
                        <a className="card-title">{data.globalJson.footerPrev.footerMenu3Title}</a>
                    </div>
                    {jsonFooterMenu3DataArray}
                </div>
            </div>
        </>
    );
}
export default FooterMenu3View;
